<template>
  <div class="page">
    <div class="title">{{ id ? "编辑" : "添加" }}招聘职位</div>

    <el-divider></el-divider>

    <el-form
      ref="form"
      :rules="rules"
      label-position="top"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="职位封面" prop="cover_image">
        <el-image
          v-if="form.cover_image"
          :src="host + form.cover_image"
          style="width: 100px; height: 100px"
          fit="cover"
          @click="chooseImage"
          @error="form.cover_image = ''"
        ></el-image>
        <div v-else class="add_photo" @click="chooseImage">
          <i class="el-icon-plus"></i>
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="uploadImage"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
      </el-form-item>

      <el-form-item label="职位名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="招聘人数" prop="need_num">
        <el-input-number
          v-model="form.need_num"
          controls-position="right"
          :min="1"
          :max="100000"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="薪资区间">
        <el-col :span="0.5">
          <el-form-item prop="salary_min">
            <el-input-number
              v-model="form.salary_min"
              controls-position="right"
              :min="1"
              :max="form.salary_max"
            ></el-input-number>
            千
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <div style="text-align: center">~</div>
        </el-col>
        <el-col :span="0.5">
          <el-form-item prop="salary_max">
            <el-input-number
              v-model="form.salary_max"
              controls-position="right"
              :min="form.salary_min"
              :max="1000"
            ></el-input-number
            >千
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="工作类型" prop="job_type">
        <!-- <el-input
          v-model="form.job_type"
          placeholder="例：全职/兼职/小时工"
        ></el-input> -->
        <el-select v-model="form.job_type" placeholder="请选择">
          <el-option
            v-for="item in job_type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="工作经验要求" prop="experience">
        <el-select v-model="form.experience" placeholder="请选择">
          <el-option
            v-for="item in experience_options"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="学历要求" prop="degree">
        <el-select v-model="form.degree" placeholder="请选择">
          <el-option
            v-for="item in degree_options"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="招聘时间" prop="recruit_time">
        <el-date-picker
          v-model="form.recruit_time"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="工作地址" prop="dataModel">
        <el-cascader
          v-model="form.dataModel"
          placeholder="请选择户口所在地省市区"
          :options="cityList"
          :props="{ value: 'label' }"
        >
        </el-cascader>
      </el-form-item>

      <el-form-item label="公司福利" prop="welfare_ids">
        <el-select v-model="form.welfare_ids" multiple placeholder="请选择">
          <el-option
            v-for="item in welfare_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="职位详情" prop="content">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="form.content"
        >
        </el-input>
      </el-form-item>

      <el-form-item class="form_btns">
        <div class="form_btns">
          <div class="custom_button plain no_select" @click="onSubmit">
            保存
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import City from "@/minix/getCity";
export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      cityList: [], //城市列表
      form: {
        name: "",
        need_num: 1,
        experience: "",
        degree: "",
        content: "",
        job_type: "",
        recruit_time: "",
        status: 1,
        salary_min: 1,
        salary_max: 10,
        welfare_ids: [],
        cover_image: "",
        dataModel: ["", "", ""], //地址
      },
      job_type_options: [
        {
          value: 1,
          label: "全职",
        },
        {
          value: 2,
          label: "小时工",
        },
      ],
      experience_options: [],
      welfare_list: [],
      degree_options: [],
      rules: {
        dataModel: [
          {
            required: true,
            message: "请选择工作地址",
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            message: "请上传职位图片",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请填写职位名称",
            trigger: "blur",
          },
        ],
        need_num: [
          {
            required: true,
            message: "请填写招聘人数",
            trigger: "blur",
          },
        ],
        experience: [
          {
            required: true,
            message: "请填写工作经验要求",
            trigger: "blur",
          },
        ],
        degree: [
          {
            required: true,
            message: "请填写学历要求",
            trigger: "blur",
          },
        ],
        job_type: [
          {
            required: true,
            message: "请填写工作类型",
            trigger: "blur",
          },
        ],
        recruit_time: [
          {
            required: true,
            message: "请选择招聘时间",
            trigger: "blur",
          },
        ],
        salary_min: [
          {
            required: true,
            message: "请填写最低薪资",
            trigger: "blur",
          },
        ],
        salary_max: [
          {
            required: true,
            message: "请填写最高薪资",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请填写职位详情",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["id"],
  created() {
    // 城市列表
    this.cityList = City;
    // 加载详情
    if (this.id) {
      this.getJobDetail({ id: this.id })
        .then((res) => {
          console.log(res);
          res = res.data.data;
          this.form = {
            cover_image: res.cover_image,
            name: res.name,
            need_num: res.need_num,
            experience: res.experience,
            degree: res.degree,
            content: res.content,
            job_type: res.job_type,
            recruit_time: res.recruit_time,
            status: res.status,
            salary_min: res.salary_min,
            salary_max: res.salary_max,
            welfare_ids: res.welfare_ids.map(Number),
            dataModel: [res.province, res.city, res.area],
          };
        })
        .catch((e) => {
          console.error(e);
        });
    }

    this.getExperienceDegreeData()
      .then((res) => {
        console.log(res);
        this.experience_options = res.data.data.experience;
        this.degree_options = res.data.data.degree;
      })
      .catch((e) => {
        console.error(e);
      });

    this.getjobwelfare().then((res) => {
      console.log(res);
      this.welfare_list = res.data.data;
    });
  },
  methods: {
    ...mapActions("recruitment", [
      "postAddJob",
      "postEditJob",
      "getJobDetail",
      "getExperienceDegreeData",
      "getjobwelfare",
    ]),
    ...mapActions(["uploadFile"]),

    /**
     * 选择图片
     */
    chooseImage() {
      this.$refs.fileInput.click();
    },

    /**
     * 上传图片
     */
    uploadImage(file) {
      let loading = this.$loading();
      console.log(file);
      this.uploadFile(file.target.files[0])
        .then((res) => {
          loading.close();
          console.log(res);
          this.form.cover_image = res.data.data.full_link;
        })
        .catch((err) => {
          loading.close();
          console.error(err);
          this.$message.error(err.msg);
        });
    },

    test(id) {
      console.log(this.form.welfare_ids);
      let model = {};
      for (let i = 0; i < id.length; i++) {
        this.welfare_list.find((item) => {
          if (item.id === id[i]) {
            model.welfare_id = model.welfare_id + "," + id[i];
            model.welfare_name = model.welfare_name + "," + item.name;
          }
        });
      }
      console.log(model);
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          let loading = this.$loading();
          let params = {
            ...this.form,
          };
          let {
            dataModel: [province, city, area],
          } = this.form;
          params.province = province;
          params.city = city;
          params.area = area;
          console.log("参数", params);

          if (this.id) {
            // 编辑
            params.id = this.id;

            this.postEditJob(params)
              .then((res) => {
                console.log(res);
                this.$message.success(res.data.msg);
                setTimeout(() => {
                  loading.close();
                  this.$router.back();
                }, 2000);
              })
              .catch((e) => {
                console.error(e);
              });
          } else {
            // 添加
            this.postAddJob(params)
              .then((res) => {
                console.log(res);
                this.$message.success(res.data.msg);
                setTimeout(() => {
                  loading.close();
                  this.$router.back();
                }, 2000);
              })
              .catch((e) => {
                console.error(e);
              });
          }
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: #222222;
}

.el-input {
  width: 400px;
  height: 48px;
  font-size: 14px;
  color: #222222;
}

.el-select {
  width: 400px;
}

.el-textarea {
  width: 400px;
  font-size: 14px;
  color: #222222;
}

.form_btns {
  display: flex;
  align-items: center;

  .custom_button {
    width: 142px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
}

.add_photo {
  width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  color: #dcdfe6;
}
</style>
